import React, {useCallback, useRef} from 'react';
import LOGO from './logo.svg';
import './App.css';
import {ZoomMtg} from '@zoomus/websdk';
import {generateSignature} from './Signature';
import axios from 'axios';
import {useMount, useUnmount} from 'ahooks';
import * as dotenv from 'dotenv';

//load dotenv
dotenv.config({});

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.17.0/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

function App() {
  // var authEndpoint = ''
  
  const sdkKey = process.env.REACT_APP_ZOOM_MEETING_SDK_KEY;
  const userEmail = ''
  const registrantToken = ''
  const zakToken = ''
  const leaveUrl = process.env.REACT_APP_LEAVE_URL;
  const homeUrl = process.env.REACT_APP_HOME_URL;

  function exit() {
    window.location.replace(homeUrl);
  }

  function validateMeetingArgs(args) {
    console.log(sdkKey, leaveUrl, homeUrl, process.env.REACT_APP_BASE_API_URL, process.env.REACT_APP_ZOOM_MEETING_SDK_SECRET);
    return args.meeting_number &&
      args.meeting_pwd &&
      args.meeting_email &&
      (
        args.meeting_role != null &&
        [0, 1, '0', '1'].includes(args.meeting_role)
      ) &&
      args.meeting_lang &&
      args.meeting_topic &&
      args.display_name &&
      (
        args.meeting_china != null &&
        [0, 1, '0', '1'].includes(args.meeting_china)
      ) &&
      args.request_id;
  }

  const url = window.location.href;
  const meetingArgs = useRef({});
  const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

  const params = useRef(null);
  const encryptedLink = useRef('');

  const populateMeetingArgs = useCallback(async () => {
    let newParams = [];

    const fullEndpoint = url.split('/?');
    if(fullEndpoint.length === 2) {
      params.current = fullEndpoint[1].split('&');

      if(params.current.length) {
        encryptedLink.current = params.current[0].split('=')[1];

        try {
          const decryption = await axios.post(
            baseApiUrl + '/decrypt',
            {
              "value": params.current[0].split('=')[1]
            }
          );

          newParams = decryption.data.data.split('&');
        } catch (error) {
          console.log(error);
        }

        if(newParams.length) {
          newParams.forEach((keyValue) => {
            const parts = keyValue.split('=');
            meetingArgs.current[parts[0]] = parts[1];
          });
        }
      }
    }
  }, [encryptedLink, baseApiUrl]);

  async function getSignature() {
    try {
      await populateMeetingArgs();
      console.log(meetingArgs.current);

      if(validateMeetingArgs(meetingArgs.current)) {
        /*const validationEndPoint = "/booking/meeting-link/validate?link="+encryptedLink.current;
        const validateLink = await axios.get(baseApiUrl + validationEndPoint);

        if(!validateLink.data.status) {
          alert(validateLink.data.message);

          return exit();
        }*/

        const signature = await generateSignature(
          meetingArgs.current.meeting_number, 
          meetingArgs.current.meeting_role
        );
        startMeeting(signature);
      } else {
        alert('Ungültiger Link');
        return exit();
      }
    } catch (error) {
      console.log(error);
      console.log(error.message);

      alert(error?.response?.data?.message);

      return exit();
    }
  }

  function startMeeting(signature) {
    document.getElementById('nav-tool').style.display = 'none';
    document.getElementById('zmmtg-root').style.display = 'block';

    ZoomMtg.init({
      leaveUrl: leaveUrl + `${encryptedLink.current.length ? '?link=' + encryptedLink.current : ''}`,
      success: (success) => {
        console.log(success)

        ZoomMtg.join({
          signature: signature,
          sdkKey: sdkKey,
          meetingNumber: meetingArgs.current.meeting_number,
          passWord: meetingArgs.current.meeting_pwd,
          userName: meetingArgs.current.display_name,
          userEmail: userEmail,
          tk: registrantToken,
          zak: zakToken,
          success: (success) => {
            console.log(success);
          },
          error: (error) => {
            console.log(error)
          }
        });
      },
      error: (error) => {
        console.log(error)
      },
      disableInvite: true,
      showMeetingHeader: true
    });

  }

  useMount(() => {
    async function init() {
      await populateMeetingArgs();
      await getSignature();
      document.getElementById('meeting-topic').innerHTML = meetingArgs.current.meeting_topic;
    }

    init().then(r => console.log('meeting started'));

    return () => {
      // setMeetingTopic('');
      // setDisplayName('');
    }
  });

  useUnmount(() => {
    async function deInit() {
      ZoomMtg.leaveMeeting({
        confirm: true,
        success: () => {
          console.log('meeting ended');
        }
      });
    }

    deInit().then(r => console.log('meeting ended'));
  });

  const checkCss = setInterval(() => {
    const button = document.querySelector('.joinWindowBtn');
    if(document.body.contains(button)) {
      document.querySelector('.joinWindowBtn').innerHTML = 'Beitreten';
      document.querySelector('.joinWindowBtn').title = 'Beitreten';
      clearInterval(checkCss);
      console.log('one');
    }
  }, 2000);

  return (
    <div className="App">
      <nav id="nav-tool" className="navbar navbar-inverse navbar-fixed-top bg-pri">
        <div className="container meeting-details">
            <div className='container'>
                <p className="color-pri f-weight-900 f-size-3" id="meeting-topic">{"Legalbeat Meeting"}</p>
            </div>
        </div>
      </nav>
      <main style={{display: 'none'}}>
        <h1>Zoom Meeting SDK Sample React</h1>

        <button onClick={getSignature}>Join Meeting</button>
      </main>
    </div>    
  );
}

export default App;
