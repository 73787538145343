import * as KJUR from 'jsrsasign';

export async function generateSignature(
  meetingNumber,
  role
) {
  const sdkKey = process.env.REACT_APP_ZOOM_MEETING_SDK_KEY;
  const sdkSecret = process.env.REACT_APP_ZOOM_MEETING_SDK_SECRET;

  const iat = Math.round(new Date().getTime() / 1000) - 30;
  const exp = iat + 60 * 60 * 2;

  const oHeader = { alg: 'HS256', typ: 'JWT' };

  const oPayload = {
    sdkKey: sdkKey,
    mn: meetingNumber,
    role: role,
    iat: iat,
    exp: exp,
    appKey: sdkKey,
    tokenExp: exp
  };

  const sHeader = JSON.stringify(oHeader);
  const sPayload = JSON.stringify(oPayload);
  const signature = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, sdkSecret);

  // console.log(signature);

  return signature;
};